<template>
  <v-card>
    <v-card-title>Начисление средств</v-card-title>
    <v-card-text v-if="!loading">
      <div>
        <v-autocomplete
          v-model="operation.company"
          :items="filteredCompanies"
          item-text="name"
          item-value="id"
          label="Компания"
          :rules="[rule.require]"
        ></v-autocomplete>
        <v-container
          fluid
        >
          <v-row>
            <TextField type="number" step=".01" min="0" v-model="operation.sum" :rules="[rule.number]" label="Сумма операции" hide-spin-buttons/>
            <v-spacer />
            <v-checkbox
              v-model="operation.correction"
              label="Изъятие"
            ></v-checkbox>
          </v-row>
        </v-container>
        <v-textarea
          label="Комментарий"
          v-model="operation.comment"
          :rules="[rule.require]"
        >

        </v-textarea>
        <span v-if="error.state" style="color: red;">{{ error.text }}</span>
        <v-alert class="success" dark v-if="success.state">{{ success.message }}</v-alert>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <Loader />
    </v-card-text>
    <v-card-actions>
      <v-btn color="green" dark @click="onConfirmation">Провести операцию</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card v-if="!loading">
        <v-card-title>
          Подтверждение
        </v-card-title>
        <v-card-text v-if="operation.company != undefined">
          После транзакции баланс компании будет составлять {{ operation.correction ? (company_balance - operation.sum).toFixed(2) : (company_balance + +operation.sum).toFixed(2) }} руб.<br />
          Вы уверены, что хотите {{ operation.correction ? 'списать' : 'начислить' }} {{ (+operation.sum).toFixed(2) }} рублей {{ operation.correction ? 'со счета' : 'на счет' }} компании "{{ company_name }}"?
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="operation.sended" color="success" outlined width="200px" @click="send_operation">Да</v-btn>
          <v-spacer />
          <v-btn color="red" outlined @click="dialog=false" width="200px">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

  </v-card>
</template>

<script>

import {mapGetters} from 'vuex'
import TextField from '../components/ui/TextField.vue'
import Loader from '../components/ui/Loader.vue'

export default {
  name: 'Operations',
  components: {
    TextField, Loader
  },
  data: () => ({
    loading: true,
    dialog: false,
    operation: {
      sended: false,
      company: undefined,
      correction: false,
      sum: 0,
      comment: '',
    },    
    error: {
      state: false,
      text: '',
    },
    success: {
      state: false,
      message: ''
    }
  }),
  async mounted() {
    await this.$store.dispatch('load_companies')
    // this.operation.company = this.filteredCompanies[0].id
    this.loading = false
  },
  computed: {
    ...mapGetters(['companies', ]),
    rule(){
      return {
        require: (value) => !!value || 'Необходимо заполнить!',
        number: value => {
          if (parseFloat(value) > 0) { 
            return true
          }
          return 'Задана некорректная сумма'
        }
      }
    },
    filteredCompanies() {
      return this.companies.filter(elem => !elem.deleted)
    },
    company_name() {
      return this.filteredCompanies.find(el => el.id == this.operation.company).name
    },
    company_balance() {
      return this.filteredCompanies.find(el => el.id == this.operation.company).balance
    },
    request_operation() {
      return {
        type: this.operation.correction ? 'CORRECTION' : 'ACCRUAL',
        sum: this.operation.sum,
        comment: this.operation.comment
      }
    },
  },
  methods: {
    onConfirmation() {
      if (this.operation.sum > 0 && this.operation.comment != '') {
        this.dialog = true 
      } else {
        this.error.state = true
        this.error.text = 'Необходимо заполнить все поля'
        setTimeout(() => this.error.state = false, 2000)
      } 
    },
    async send_operation() {
      this.operation.sended = true
      try {
        let response = await this.$store.dispatch('send_operation', {
          company_id: this.operation.company,
          operation: this.request_operation
        })
        this.operation.sended = false
        this.dialog = false
        this.success.state = true
        this.success.message = 'Операция проведена успешно! Текущий баланс компании составляет: ' + response.company.balance.toFixed(2) + ' руб.'
        this.$store.commit('update_company', response.company)
        setTimeout(() => this.success.state = false, 6000)
      } catch(e) {
        this.error.state = true
        this.error.text = e.message
        setTimeout(() => this.error.state = false, 2000)
      }
      this.operation.sended = false
      this.dialog = false
      
    }
  }
}
</script>